import { analyzeAndValidateNgModules, Type } from "@angular/compiler";
import { Component, HostListener, OnInit, EventEmitter, Output, } from "@angular/core";
import { ViewChild, ElementRef } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { StepperOrientation } from "@angular/cdk/stepper";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { MatRadioModule } from "@angular/material/radio";
import jsPDF from "jspdf";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from "html-to-pdfmake";
import { PageEvent } from "@angular/material/paginator";
import { MatCheckboxModule } from "@angular/material/checkbox";

import { RelationshipService } from "../../shared/services/relationship.service";
import { AngularFirestore } from "@angular/fire/firestore";


@Component({
  selector: 'app-relationship',
  templateUrl: './relationship.component.html',
  styleUrls: ['./relationship.component.css']
})
export class RelationshipComponent implements OnInit {
  thirdFormGroup: FormGroup;
  fourthFormGroup: FormGroup;
  fifthFormGroup: FormGroup;
  sixFormGroup: FormGroup;

  address;
  isVertical = false;
  orientation: StepperOrientation = "vertical";
  smallScreen: boolean;
  completed: boolean = false;
  state: string;
  title = "htmltopdf";
  public searchStr: string = "";
  public age;
  anothername: string;
  residential: string;
  public mid: string = "";
  lname;
  occupation;
  mail;
  digital;
  legalDocsname;
  gender;

  propertyList;
  sole_owned;
  owned;
  property;

  married;
  spouse;
  spouse_name;
  spouse_m_name;
  spouse_l_name;
  spouse_maiden_name;
  residential_add;
  digital_add;
  dependants;
  children;
  living_children;
  trustee;
  Dependants_name;
  Dependants_m_name;
  Dependants_l_name;
  Dependants_ress_add;
  Dependants_ress_mail;
  Dependants_dig_add;
  deposing;

  executors_name;
  executors_m_name;
  executors_l_name;
  executors_resi;
  executors_mail_add;
  executors_digi_add;
  executors_occupation;
  move_land;
  no_property;
  land_owned;
  more_spouse;
  any_spouse_dependant;
  spouse_beneficiary;

  funeral_exp;
  final_words;

  residual;

  myVar1;
  myVar2;
  myVar3;
  myVar4;
  // pdfTa

  listProp = "no";

  new_form = 'no';
  editing = 'no';
  basic_doc_id;
  basic;

  @Output() callParent = new EventEmitter();

  constructor(private _formBuilder: FormBuilder, public relationship_service: RelationshipService, private db: AngularFirestore) { }

  ngOnInit() {
    this.thirdFormGroup = this._formBuilder.group({
      married: [""],

      dependants: [""],

      living_children: [""],

    });

    //RETRIEVE RELATIONSHIP DETAILS

    this.relationship_service
      .retrieve_relationship()
      .subscribe((basic) => {
        console.log("CHECK BASIC REQUIR:::", basic)
        this.basic = basic
        let data = basic[0];
        // this.basic_doc_id = data.id
        console.log("LENGHT:::", this.basic.length)

        if (basic.length > 0) {
          let data = basic[0];
          this.basic_doc_id = data.id
       
          this.married = data.data.married;
          this.dependants = data.data.dependants;
          this.living_children = data.data.living_children;

          

          console.log("married:::", this.married)

        }
      })
  }



  public modelChange(str: string) {


    // this.married = this.thirdFormGroup.value.married;


    // this.dependants = this.thirdFormGroup.value.dependants;
    // this.children = this.thirdFormGroup.value.children;
    // this.living_children = this.thirdFormGroup.value.living_children;


    // console.log("DEPOSINGG", this.deposing);


  }

  edit_form() {
    this.new_form = 'yes'
    this.editing = 'yes'
    this.listProp = "no"
  }



  save_relationship() {
    let formData: any = this.thirdFormGroup.value as {};
    console.log("VALUE ", formData);

    this.relationship_service.save_relation(formData)

    this.new_form = 'no'
    this.callParent.emit("completed3");

  }

  deleteForm(data) {
    console.log("DELETE VALUE ", data);
    console.log("DELETE ID ", data.id);


    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid


    if (userUid) {
      console.log("USER EXISTS--")
      return new Promise<any>((resolve, reject) => {
        this.db
          .collection("relationship")
          .doc(data.id)
          .delete()
      });

    }
  }


  update() {
    let formData: any = this.thirdFormGroup.value as {};
    console.log("VALUE ", formData);

    this.relationship_service.update_property(this.basic_doc_id, formData)

    this.new_form = 'no'
    this.editing = 'no'
    this.callParent.emit("completed3");

  }

}

import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { HomeComponent } from "./components/layout//home/home.component";
import { CreateWillComponent } from "./create-will/create-will.component";
import { SignupComponent } from "./signup/signup.component";
import { LoginComponent } from "./login/login.component";
import { PreambleComponent } from "./preamble/preamble.component";
import { DocumentComponent } from './document/document.component';
import { FinalDocumentsComponent } from './final-documents/final-documents.component';

const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: "", redirectTo: "/home", pathMatch: "full" },
  { path: "CreateWill", component: CreateWillComponent },
  { path: "signUp", component: SignupComponent },
  { path: "login", component: LoginComponent },
  { path: "preamble", component: PreambleComponent },
  { path: "documents", component: DocumentComponent },
  { path: "final-documents", component: FinalDocumentsComponent },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }

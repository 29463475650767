import { analyzeAndValidateNgModules, Type } from "@angular/compiler";
import { Component, HostListener, OnInit } from "@angular/core";
import { ViewChild, ElementRef } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { StepperOrientation } from "@angular/cdk/stepper";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { MatRadioModule } from "@angular/material/radio";
import jsPDF from "jspdf";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from "html-to-pdfmake";
import { PageEvent } from "@angular/material/paginator";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { AuthService } from "../shared/services/auth.service";
import { Router } from "@angular/router";
import { RelationshipService } from "../shared/services/relationship.service";
import { ChildrenService } from "../shared/services/children.service";
import { TransactionsService } from "../shared/services/transactions.service";
import { PersonalDetailsService } from "../shared/services/personal-details.service";
import { PropertiesService } from "../shared/services/properties.service";
import { SpousalService } from "../shared/services/spousal.service";
import { DependantsService } from "../shared/services/dependants.service";
import { BeneficiariesService } from "../shared/services/beneficiaries.service";

// import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
// import { MatBottomSheet, MatBottomSheetRef } from "@angular/material";

@Component({
  selector: "app-create-will",
  templateUrl: "./create-will.component.html",
  styleUrls: ["./create-will.component.css"],
})
export class CreateWillComponent implements OnInit {
  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  fourthFormGroup: FormGroup;
  fifthFormGroup: FormGroup;
  sixFormGroup: FormGroup;
  address;
  isVertical = false;
  orientation: StepperOrientation = "vertical";
  smallScreen: boolean;
  completed: boolean = false;
  state: string;
  title = "htmltopdf";
  public searchStr: string = "";
  public age;
  anothername: string;
  residential: string;
  public mid: string = "";
  lname;
  occupation;
  mail;
  digital;
  legalDocsname;
  gender;
  propertyList;
  sole_owned;
  owned;
  property;
  married;
  spouse;
  spouse_name;
  spouse_m_name;
  spouse_l_name;
  spouse_maiden_name;
  residential_add;
  digital_add;
  dependants;
  children;
  under_18;
  trustee;
  Dependants_name;
  Dependants_m_name;
  Dependants_l_name;
  Dependants_ress_add;
  Dependants_ress_mail;
  Dependants_dig_add;
  deposing;
  executors_name;
  executors_m_name;
  executors_l_name;
  executors_resi;
  executors_mail_add;
  executors_digi_add;
  executors_occupation;
  move_land;
  no_property;
  land_owned;
  more_spouse;
  any_spouse_dependant;
  spouse_beneficiary;
  funeral_exp;
  final_words;
  residual;
  myVar1;
  myVar2;
  myVar3;
  myVar4;
  basic_val;
  bene;
  // pdfTable: any;

  // favoriteSeason: string;
  // seasons: string[] = ["Winter", "Spring", "Summer", "Autumn"];

  // MatPaginator Inputs
  length = 100;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  step: any = 0;
  // MatPaginator Output
  pageEvent: PageEvent;

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput
        .split(",")
        .map((str) => +str);
    }
  }

  @ViewChild("pdfTable") pdfTable: ElementRef;
  @HostListener("window:resize") onWindowResize() {
    if (window.innerWidth <= 768) {
      this.isVertical = true;
    } else {
      this.isVertical = false;
    }
  }
  msg;
  messagelist;
  rela;
  isMarried;
  child;
  hasGuardian;
  hasTrustee
  hasChildren;
  hasDependants;
  personal;
  spousal;
  gifts_allo;
  executors;
  guardian;
  trust_creation;
  trustee1;
  penss;
  resid;
  wills
  wishes;
  instruction
  funeral

  constructor(
    private _formBuilder: FormBuilder,
    private breakpointObserver: BreakpointObserver,
    public authService: AuthService,
    public router: Router,
    public relationship_service: RelationshipService,
    public child_service: ChildrenService,
    public transService: TransactionsService,
    public personDetails: PersonalDetailsService,
    public property_service: PropertiesService,
    public spousal_service: SpousalService,
    public dependant_service: DependantsService,
    public beneficiary_service: BeneficiariesService
  ) {
    breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small])
      .subscribe((result) => {
        this.smallScreen = result.matches;
      });

    this.userDetails()

    this.transService
      .retrieve_basic_requirements()
      .subscribe((basic) => {
        console.log("CHECK BASIC REQUIR:::", basic)
     

        if (basic.length > 0) {
          let data = basic[0];
        
          this.myVar1 = data.above_minimum_age
          this.myVar2 = data.member_armed_forces

          console.log("BASIC AGE MINIMUM", this.myVar1 )
   
        }
      })
    
    ///instruction
    this.beneficiary_service
      .retrieve_special_instructions()
      .subscribe((basic) => {
        console.log("CHECK BASIC REQUIR:::", basic)
        this.instruction = basic
       
      })
    
    //PENSIONS

    this.beneficiary_service
      .retrieve_pension()
      .subscribe((basic) => {
        console.log("CHECK BASIC REQUIR:::", basic)
        this.penss = basic
     
      })
    
    
    this.beneficiary_service
      .retrieve_final()
      .subscribe((basic) => {
        console.log("CHECK BASIC REQUIR:::", basic)
        this.wishes = basic
     
      })
    
    this.beneficiary_service
      .retrieve_residual()
      .subscribe((basic) => {
        console.log("RESIDUAL:::", basic)
        this.resid = basic
     
      })
    
    //Will reading
    this.beneficiary_service
      .retrieve_will()
      .subscribe((basic) => {
        console.log("CHECK BASIC REQUIR:::", basic)
        this.wills = basic
       
      })
    
    //funeral reading
    this.beneficiary_service
      .retrieve_funeral()
      .subscribe((basic) => {
        console.log("CHECK BASIC REQUIR:::", basic)
        this.funeral = basic

      })
    
   

    this.relationship_service
      .retrieve_relationship()
      .subscribe((basic) => {
        this.rela = basic
        if (basic.length > 0) {
           let data = basic[0];
          this.isMarried = data.data.married
          this.hasChildren = data.data.living_children 
          this.hasDependants = data.data.dependants 

        }
        else {
          this.isMarried = null
        }
      })
    
    this.beneficiary_service
      .retrieve_trustee()
      .subscribe((basic) => {
        console.log("CHECK BASIC REQUIR:::", basic)
        this.trustee1 = basic
     
      })
    

    this.beneficiary_service
      .retrieve_guardian()
      .subscribe((basic) => {
        console.log("CHECK BASIC REQUIR:::", basic)
        this.guardian = basic

    
      })
    
    //Trust Creation
    this.beneficiary_service
      .retrieve_trust()
      .subscribe((basic) => {
        console.log("CHECK BASIC REQUIR:::", basic)
        this.trust_creation = basic
      
      })
    

    this.child_service
      .retrieve_child()
      .subscribe((basic) => {
        console.log("CHECK BASIC REQUIR:::", basic)
        this.child = basic

        if (basic.length > 0) {
          let data = basic[0];
          this.hasGuardian = data.data.guardian
          this.hasTrustee = data.data.trustee
        }
        else {
          this.hasGuardian = null
          this.hasTrustee = null
        }
      })
    //EXECUTORS
    this.beneficiary_service
      .retrieve_executors()
      .subscribe((basic) => {
        console.log("CHECK BASIC REQUIR:::", basic)
        this.executors = basic
        console.log("LENGHT:::", this.executors.length)

      })


      //Retrive Personal Details
    this.personDetails
      .retrieve_personal()
      .subscribe((basic) => {
        console.log("CHECK BASIC REQUIR:::", basic)
        this.personal = basic
          console.log("PERSONAL LENGHT:::", this.personal.length)

      })
    
    //GIFT ALLOCA
    
    this.beneficiary_service
      .retrieve_gift_allocation()
      .subscribe((basic2) => {
        console.log("CHECK BASIC REQUIR:::", basic2)
        this.gifts_allo = basic2

      })
    
    
    //RETRIEVE RESIDENCE DETAILS
    this.property_service
      .retrieve_residence()
      .subscribe((basic) => {
        console.log("PROPERTIES:::", basic)
        this.property = basic
  
        console.log("LENGHT:::", this.property.length)
        if (basic.length > 0) {
          let data = basic[0];
          this.no_property = data.data.no_property
          console.log("no_property:::", this.no_property)
         
        }
        else {
          this.no_property = null
          console.log("no_property:::", this.no_property)
       
        }
      })

    //RETRIEVE SPOUSAL DETAILS
    this.spousal_service
      .retrieve_spousal()
      .subscribe((basic) => {
        console.log("CHECK BASIC REQUIR:::", basic)
        this.spousal = basic
        console.log("LENGHT:::", this.spousal.length)

      })
    
      //RETRIEVE DEPENDANTS DETAILS
    this.dependant_service
      .retrieve_dependants()
      .subscribe((basic) => {
        console.log("CHECK BASIC REQUIR:::", basic)
        this.dependants = basic
    
        console.log("LENGHT:::", this.dependants.length)

      })
    
    this.beneficiary_service
      .retrieve_beneficiaries()
      .subscribe((basic) => {
        console.log("CHECK BASIC REQUIR:::", basic)
        this.bene = basic
        console.log("LENGHT:::", this.bene.length)

      })

  }


  addItem($event) {
    this.basic_val = $event
    console.log("CHECKING CHILD VALUE TO PARENT::", this.basic_val)
  }

  userDetails() {
    //this.authService.userDetails()

    let userData = JSON.parse(localStorage.getItem("user"));
    if (userData) {
      let userUid = userData.uid
      console.log("USER DATA::", userData)
      console.log("USER UID::", userUid)
    }
    else {
      console.log("USER DATA::", userData)
      this.router.navigate(["login"]);

    }

  }
  
  getMsgFromBaby($event) {
    this.msg = $event;
    console.log("Message from child " + this.msg)

    this.messagelist = this.msg
  }

  public downloadAsPDF() {
    const doc = new jsPDF();

    const pdfTable = this.pdfTable.nativeElement;

    var html = htmlToPdfmake(pdfTable.innerHTML);

    const documentDefinition = { content: html };
    pdfMake.createPdf(documentDefinition).open();
  }

  // openBottomSheet(): void {
  //   this.bottomSheet.open();
  // }

  ngOnInit() {
    this.firstFormGroup = this._formBuilder.group({
      name: ["", Validators.required],
      age: ["", Validators.required],
      mid_name: [""],
      lname: [""],
      occupation: [""],
      residential: [""],
      mail: [""],
      digital: [""],
      anothername: [""],
      legalDocsname: [""],
      gender: [""],
    });
    this.secondFormGroup = this._formBuilder.group({
      address: ["", Validators.required],
      residential: [""],
      property: [""],
      owned: [""],
      sole_owned: [""],
      name: [""],
      propertyList: [""],
      move_land: [""],
      no_property: [""],
      land_owned: [""],
    });
    this.thirdFormGroup = this._formBuilder.group({
      married: [""],
      spouse: [""],
      spouse_name: [""],
      spouse_m_name: [""],
      spouse_l_name: [""],
      spouse_maiden_name: [""],
      residential_add: [""],
      mailing_add: [""],
      digital_add: [""],
      dependants: [""],
      children: [""],
      under_18: [""],
      trustee: [""],
      Dependants_name: [""],
      Dependants_m_name: [""],
      Dependants_l_name: [""],
      Dependants_ress_add: [""],
      Dependants_ress_mail: [""],
      Dependants_dig_add: [""],
      deposing: [""],
      more_spouse: [""],
      any_spouse_dependant: [""],
      spouse_beneficiary: [""],
    });
    this.fourthFormGroup = this._formBuilder.group({
      executors_name: [""],
      executors_m_name: [""],
      executors_l_name: [""],
      executors_resi: [""],
      executors_mail_add: [""],
      executors_digi_add: [""],
      executors_occupation: [""],
    });
    this.fifthFormGroup = this._formBuilder.group({
      funeral_exp: [""],
      final_words: [""],
    });
    this.sixFormGroup = this._formBuilder.group({
      residual: [""],
    });
  }

  toggleOrientation() {
    this.orientation =
      this.orientation === "vertical" ? "horizontal" : "vertical";
  }

  form1() {
    console.log(this.firstFormGroup.value);
  }

  form2() {
    console.log(this.secondFormGroup.value);
  }

  form3() {
    console.log(this.thirdFormGroup.value);
  }

  form4() {
    console.log(this.fourthFormGroup.value);
  }

  form5() {
    console.log(this.fifthFormGroup.value);
  }

  form6() {
    console.log(this.sixFormGroup.value);
  }

  public modelChange(str: string) {
    //FIRST FORM GROUP == PERSONAL
    this.searchStr = this.firstFormGroup.value.name;
    this.age = this.firstFormGroup.value.age;
    this.mid = this.firstFormGroup.value.mid_name;
    this.lname = this.firstFormGroup.value.lname;
    this.gender = this.firstFormGroup.value.gender;
    this.occupation = this.firstFormGroup.value.occupation;
    this.residential = this.firstFormGroup.value.residential;
    this.mail = this.firstFormGroup.value.mail;
    this.digital = this.firstFormGroup.value.digital;
    this.anothername = this.firstFormGroup.value.anothername;
    this.legalDocsname = this.firstFormGroup.value.legalDocsname;

    //SECOND FORM GROUP == RESIDENTIAL

    this.residential = this.secondFormGroup.value.residential;
    this.property = this.secondFormGroup.value.property;
    this.owned = this.secondFormGroup.value.owned;
    this.sole_owned = this.secondFormGroup.value.sole_owned;

    console.log(this.residential, this.property, this.owned, this.sole_owned);

    //THRID FORM GROUP == RELATIONSHIP

    this.married = this.thirdFormGroup.value.married;
    this.spouse = this.thirdFormGroup.value.spouse;
    this.spouse_name = this.thirdFormGroup.value.spouse_name;
    this.spouse_m_name = this.thirdFormGroup.value.spouse_m_name;
    this.spouse_l_name = this.thirdFormGroup.value.spouse_l_name;
    this.spouse_maiden_name = this.thirdFormGroup.value.spouse_maiden_name;
    this.residential_add = this.thirdFormGroup.value.residential_add;
    this.digital_add = this.thirdFormGroup.value.digital_add;
    this.dependants = this.thirdFormGroup.value.dependants;
    this.children = this.thirdFormGroup.value.children;
    this.under_18 = this.thirdFormGroup.value.under_18;
    this.trustee = this.thirdFormGroup.value.trustee;
    this.Dependants_name = this.thirdFormGroup.value.Dependants_name;
    this.Dependants_m_name = this.thirdFormGroup.value.Dependants_m_name;
    this.Dependants_l_name = this.thirdFormGroup.value.Dependants_l_name;
    this.Dependants_ress_add = this.thirdFormGroup.value.Dependants_ress_add;
    this.Dependants_ress_mail = this.thirdFormGroup.value.Dependants_ress_mail;
    this.Dependants_dig_add = this.thirdFormGroup.value.Dependants_dig_add;
    this.deposing = this.thirdFormGroup.value.deposing;

    console.log("DEPOSINGG", this.deposing);

    //FOURTH FORM GROUP == EXECUTIVE

    this.executors_name = this.fourthFormGroup.value.executors_name;
    this.executors_m_name = this.secondFormGroup.value.executors_m_name;
    this.executors_l_name = this.secondFormGroup.value.executors_l_name;
    this.executors_resi = this.secondFormGroup.value.executors_resi;
    this.executors_mail_add = this.secondFormGroup.value.executors_mail_add;
    this.executors_digi_add = this.secondFormGroup.value.executors_digi_add;
    this.executors_occupation = this.secondFormGroup.value.executors_occupation;

    //FIFTHFORM GROUP == FUNERAL

    this.funeral_exp = this.fifthFormGroup.value.funeral_exp;
    this.final_words = this.secondFormGroup.value.final_words;

    //SIXTHFORM GROUP == FUNERAL

    this.residual = this.fifthFormGroup.value.residual;
  }

  all_forms() {
    this.completed = true;
    this.state = "done";
    console.log(this.firstFormGroup.value);
    console.log(this.secondFormGroup.value);
    this.firstFormGroup.reset();
    this.secondFormGroup.reset();
    this.router.navigateByUrl("/final-documents")

  }

  save_trust() {
    console.log("i have been clicked");
  }

  submit(num) {
    this.step = num;
    console.log("STEP HERE ", this.step);
  }

  proceed(num) {
    console.log("VALUE ", this.myVar1, this.myVar2, this.myVar3, this.myVar4);

    this.step = num;
    console.log("STEP HERE ", this.step);
  }
}

import { Type } from "@angular/compiler";
import { Component, HostListener, OnInit, EventEmitter, Output } from "@angular/core";
import { ViewChild, ElementRef } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { StepperOrientation } from "@angular/cdk/stepper";

import { BeneficiariesService } from "../../shared/services/beneficiaries.service";

import { ChildrenService } from "../../shared/services/children.service";
import { AngularFirestore } from "@angular/fire/firestore";
@Component({
  selector: "app-trust-creation",
  templateUrl: "./trust-creation.component.html",
  styleUrls: ["./trust-creation.component.css"],
})
export class TrustCreationComponent implements OnInit {
  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  fourthFormGroup: FormGroup;
  public searchStr: string = "";
  public age;
  address;
  isVertical = false;
  orientation: StepperOrientation = "vertical";
  smallScreen: boolean;
  completed: boolean = false;
  state: string;
  title = "htmltopdf";
  anothername: string;
  residential: string;
  child_address;
  trustee_trust_no;
  addG;
  listProp = "no"

  showSave = "yes";

  new_form = 'no';
  editing = 'no';
  basic_doc_id;
  basic;
  child_detail;

  trustee_trust = ''
  existing_trust = ''
  trust_instruction = ''
  trust_purpose = ''
  create_trust = ''
  trust_name = ''
  new_trust_instruction = ''
  step = 0;
  children
  child_name
  @Output() callParent = new EventEmitter();

  constructor(
    private _formBuilder: FormBuilder,
    private breakpointObserver: BreakpointObserver,
    public beneficiary_service: BeneficiariesService,
    public child_service: ChildrenService,
    private db: AngularFirestore
  ) { }

  ngOnInit() {
    this.fourthFormGroup = this._formBuilder.group({
      existing_trust: [""],
      trust_instruction: [""],
      create_trust: [""],
      trustee_trust: [""],
      trust_name: [""],
      new_trust_instruction: [""],
      children: [""],
      another_trust_name: [""],
      another_trust_instruction: [""],
      trust_purpose: [""]
    });

    this.beneficiary_service
      .retrieve_trust()
      .subscribe((basic) => {
        console.log("CHECK BASIC REQUIR:::", basic)
        this.basic = basic
        let data = basic[0];

        console.log("LENGHT:::", this.basic.length)

        if (basic.length > 0) {
          let data = basic[0];
          this.basic_doc_id = data.id

      

          this.existing_trust = data.data.existing_trust
          this.trust_instruction = data.data.trust_instruction
          this.trust_purpose = data.data.trust_purpose
          this.create_trust = data.data.create_trust
          this.trustee_trust = data.data.trustee_trust
          this.trust_name = data.data.trust_name
          this.new_trust_instruction = data.data.new_trust_instruction


        }
      })
    
    this.child_service
      .retrieve_child_details_trustee()
      .subscribe((basic) => {
        console.log("CHECK BASIC REQUIR:::", basic)
        this.child_detail = basic
        let data = this.child_detail[0];

        console.log("LENGHT:::", this.child_detail.length)

        if (basic.length > 0) {
          let data = basic[0];
          this.basic_doc_id = data.id


        }
      })
  }
  params

  proceed(data) {
    let formData: any = this.fourthFormGroup.value as {};
    console.log("Data ", data);
    console.log("VALUE ", formData);

    this.step++;

    // let childName = data +" "+ lname

    // console.log("childName ", childName);


    this.params = {
      child_name: data,
      trust_name: formData.trust_name,
      trust_instruction: formData.trust_instruction,
      trust_purpose: formData.trust_purpose
   
      

    };

    console.log(" this.params ", this.params);

    this.beneficiary_service.save_trust(this.params)


    this.new_form = 'no'
    // this.editing = 'no'

    console.log("showSummary::", "new_form::", this.new_form, "listProp::", this.listProp, "editing:::", this.editing)

    this.callParent.emit("completed9");

  }

  save_trust() {
    let formData: any = this.fourthFormGroup.value as {};
    console.log("VALUE ", formData);

    this.beneficiary_service.save_trust(formData)

    this.new_form = 'no'

    // this.callParent.emit("completed12");

  }

  allocateNew() {
    console.log("<---- RESSETTIN FORM ---->>>")
    this.fourthFormGroup.reset()
    this.showSave = "yes"
    this.existing_trust = ""
    this.trust_instruction = ""
    this.create_trust = ""
    this.trustee_trust = ""
    this.trust_name = ""
    this.new_trust_instruction = ""
    this.children = ""
    this.child_name = ""
    this.trust_purpose = ""


    this.new_form = 'yes'
    // this.showSummary = false
    this.editing = 'no'
    // this.showSummaryButton = "no"


    console.log("showSummary::", "new_form::", this.new_form, "listProp::", this.listProp, "editing:::", this.editing)
  }



  public modelChange(str: string) {

  }

  newProperty2() {

    this.fourthFormGroup.reset()
    this.showSave = "yes"
    this.new_form = 'yes'
  }



  edit_form(data) {
    this.new_form = 'yes'
    this.editing = 'yes'
    this.showSave = "no"
    this.beneficiary_service
      .retrieve_trust_edit(data)
      .subscribe((basic) => {
        console.log("CHILD SERVICE:::", basic)
        console.log("DEPEM SERVICE DATA ID:::", basic.id)

        this.basic_doc_id = basic.id

        this.trust_instruction = basic.data.trust_instruction
        this.trust_purpose = basic.data.trust_purpose
        this.trust_name = basic.data.trust_name
        this.child_name = basic.data.child_name


      })
  }

  deleteForm(data) {
    console.log("DELETE VALUE ", data);
    console.log("DELETE ID ", data.id);


    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid


    if (userUid) {
      console.log("USER EXISTS--")
      return new Promise<any>((resolve, reject) => {
        this.db
          .collection("trust_creation")
          .doc(data.id)
          .delete()
      });

    }
  }



  setStep(index: number) {
    this.step = index;



  }



  update(data) {
    let formData: any = this.fourthFormGroup.value as {};
    console.log("VALUE ", formData);
    console.log("basic_doc_id ", this.basic_doc_id);

    this.params = {
      child_name: data,
      trust_name: formData.trust_name,
      trust_instruction: formData.trust_instruction,
      trust_purpose: formData.trust_purpose
    

    };

    this.beneficiary_service.update_trust(this.basic_doc_id, this.params)

    this.new_form = 'no'
    this.editing = 'no'

    // this.callParent.emit("completed12");

  }
}

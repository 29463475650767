import { Injectable } from '@angular/core';

// import { HttpClient } from "@angular/common/http";
import { map, take, switchMap, tap, first } from "rxjs/operators";
import { AuthService } from "./auth.service";
import { AngularFirestore } from "@angular/fire/firestore";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PersonalDetailsService {

  constructor(private authService: AuthService,

    private db: AngularFirestore) {


  }

  save_personal(data) {

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)
    if (userUid) {
      let basic_requirements = {
        userUID: userUid,
        data
      }

      return new Promise<any>((resolve, reject) => {
        this.db
          .collection("person_details")
          .add({ ...basic_requirements })
          .then(
            (res) => resolve(res),
            (err) => reject(err)
          );
      });
    }
  }

  update_personal(doc_id, data) {

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)

    if (userUid) {
      return new Promise<any>((resolve, reject) => {
        this.db
          .collection("person_details")
          .doc(doc_id)
          .update({
            data: data
          })

          .then(
            (res) => {
              console.log(res);
              resolve(res);
            },
            (err) => {
              console.log(err);
              reject(err);
            }
          );
      });

    }
  }


  retrieve_personal(): Observable<any> {

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)

    return this.db
      .collection("person_details", (ref) => ref.where("userUID", "==", userUid))
      .snapshotChanges()
      .pipe(
        map((snaps) =>
          snaps.map((snap) => {
            let data: any = snap.payload.doc.data() as {};
            const id = snap.payload.doc.id;
            data.id = id;
            return {
              id: id,
              ...data,
            };
          })
        ),
        //first()
      );
  }
}

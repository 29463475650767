import { Type } from "@angular/compiler";
import { Component, HostListener, OnInit, EventEmitter, Output } from "@angular/core";
import { ViewChild, ElementRef } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { StepperOrientation } from "@angular/cdk/stepper";

import { BeneficiariesService } from "../../shared/services/beneficiaries.service";
import { AngularFirestore } from "@angular/fire/firestore";

@Component({
  selector: "app-final-wishes",
  templateUrl: "./final-wishes.component.html",
  styleUrls: ["./final-wishes.component.css"],
})
export class FinalWishesComponent implements OnInit {
  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  fourthFormGroup: FormGroup;
  public searchStr: string = "";
  public age;
  address;
  isVertical = false;
  orientation: StepperOrientation = "vertical";
  smallScreen: boolean;
  completed: boolean = false;
  state: string;
  title = "htmltopdf";
  anothername: string;
  residential: string;
  child_address;
  trustee_trust_no;
  trustee_trust;
  ch_benefi;
  addG;
  listProp = "no"

  showSave = "yes";

  new_form = 'no';
  editing = 'no';
  basic_doc_id;
  basic;

  final_words = ''
  instructions = ''
  @Output() callParent = new EventEmitter();
  constructor(
    private _formBuilder: FormBuilder,
    private breakpointObserver: BreakpointObserver,
    public beneficiary_service: BeneficiariesService,
    private db: AngularFirestore
  ) { }

  ngOnInit() {
    this.fourthFormGroup = this._formBuilder.group({
      final_words: [""],
      instructions: [""],

    });
    this.beneficiary_service
      .retrieve_final()
      .subscribe((basic) => {
        console.log("CHECK BASIC REQUIR:::", basic)
        this.basic = basic
        let data = basic[0];

        console.log("LENGHT:::", this.basic.length)

        if (basic.length > 0) {
          let data = basic[0];
          this.basic_doc_id = data.id
         

          this.final_words = data.data.final_words
          this.instructions = data.data.instructions


        }
      })
  }

  form1() {
    console.log(this.firstFormGroup.value);
  }

  public modelChange(str: string) {

  }

  newProperty2() {

    this.fourthFormGroup.reset()
    this.showSave = "no"
    this.new_form = 'yes'
  }



  edit_form() {
    this.new_form = 'yes'
    this.editing = 'yes'
    this.showSave = "no"

    console.log("EDITING--", this.editing)
  }



  save_trust() {
    let formData: any = this.fourthFormGroup.value as {};
    console.log("VALUE ", formData);

    this.beneficiary_service.save_final(formData)

    this.new_form = 'no'

    this.callParent.emit("completed17");

  }


  update() {
    let formData: any = this.fourthFormGroup.value as {};
    console.log("VALUE ", formData);

    this.beneficiary_service.update_final(this.basic_doc_id, formData)

    this.new_form = 'no'
    this.editing = 'no'

    this.callParent.emit("completed17");

  }


  deleteForm(data) {
    console.log("DELETE VALUE ", data);
    console.log("DELETE ID ", data.id);


    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid


    if (userUid) {
      console.log("USER EXISTS--")
      return new Promise<any>((resolve, reject) => {
        this.db
          .collection("final_wishes")
          .doc(data.id)
          .delete()
      });

    }
  }
}

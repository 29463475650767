import { Component, OnInit } from "@angular/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatIconModule } from "@angular/material/icon";
// import { DomSanitizer } from "@angular/platform-browser";
// import { MatIconRegistry } from "@angular/material";

@Component({
  selector: "app-preamble",
  templateUrl: "./preamble.component.html",
  styleUrls: ["./preamble.component.css"],
})
export class PreambleComponent implements OnInit {
  constructor() {
    // iconRegistry.addSvgIcon(
    //   "thumbs-up",
    //   sanitizer.bypassSecurityTrustResourceUrl("assets/images/icon.svg")
    // );
  }

  ngOnInit() { }
  
  downloadPDF(): void {
    const link = document.createElement('a');
    link.href = '/assets/will.pdf';
    link.download = 'file.pdf';
    link.target = '_blank';
    link.click();
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { ViewChild, ElementRef } from "@angular/core";
import jsPDF from "jspdf";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from "html-to-pdfmake";
import { PersonalDetailsService } from "../shared/services/personal-details.service";
import { BeneficiariesService } from "../shared/services/beneficiaries.service";
import { SpousalService } from "../shared/services/spousal.service";
import { ChildrenService } from "../shared/services/children.service";
import { PropertiesService } from "../shared/services/properties.service";
import { DependantsService } from "../shared/services/dependants.service";


@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.css']
})
export class DocumentComponent implements OnInit {

  @ViewChild("pdfTable") pdfTable: ElementRef;
  basic;
  basic2;
  basic3;
  basic4;
  basic5;
  basic6;
  basic7;
  basic8;
  basic9;
  basic10;
  trust_name;
  basic11;
  basic12;
  basic13;

  @Input() msg: any[];
  // @Input('childToMaster') masterName: string;

  @Input() messagelist: any[];

  constructor(public personDetails: PersonalDetailsService, public beneficiary_service: BeneficiariesService, public spousal_service: SpousalService, public child_service: ChildrenService, public property_service: PropertiesService, public dependant_service: DependantsService) { 
    console.log("Messge Input" + this.messagelist)
  }

  ngOnInit() {
console.log("Messge Input"+ this.msg)
    this.personDetails
      .retrieve_personal()
      .subscribe((basic) => {
        this.basic = basic[0]
      })

    this.beneficiary_service
      .retrieve_executors()
      .subscribe((basic) => {
        console.log("CHECK BASIC REQUIR:::", basic)
        this.basic2 = basic

      })

    this.beneficiary_service
      .retrieve_trustee()
      .subscribe((basic) => {
        this.basic3 = basic

      })

    this.spousal_service
      .retrieve_spousal()
      .subscribe((basic) => {
        this.basic4 = basic[0]

      })

    this.child_service
      .retrieve_child_details()
      .subscribe((basic) => {
        this.basic5 = basic
        console.log("CHILDRENR:::", this.basic5)

      })

    this.beneficiary_service
      .retrieve_will()
      .subscribe((basic) => {

        this.basic6 = basic


      })

    this.property_service
      .retrieve_residence()
      .subscribe((basic) => {
        this.basic7 = basic
      })


    this.beneficiary_service
      .retrieve_gift_allocation()
      .subscribe((basic2) => {

        this.basic8 = basic2

      })

    this.beneficiary_service
      .retrieve_guardian()
      .subscribe((basic) => {
        this.basic9 = basic
      })


    this.beneficiary_service
      .retrieve_trust()
      .subscribe((basic) => {
        this.basic10 = basic
        this.trust_name = basic[0]
      })


    this.dependant_service
      .retrieve_dependants()
      .subscribe((basic) => {
        this.basic11 = basic
      })

    this.beneficiary_service
      .retrieve_residual()
      .subscribe((basic) => {
        this.basic12 = basic
      })

    this.beneficiary_service
      .retrieve_final()
      .subscribe((basic) => {
        this.basic13 = basic

      })
  }


  public downloadAsPDF() {
    const doc = new jsPDF();

    const pdfTable = this.pdfTable.nativeElement;

    var html = htmlToPdfmake(pdfTable.innerHTML);

    const documentDefinition = { content: html };
    pdfMake.createPdf(documentDefinition).open();
  }

}

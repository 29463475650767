import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent implements OnInit {
  loading = false;
  user_id;
  access_code;


  constructor(private router: Router) { }

  ngOnInit() {
  }


  onSubmit() {
    this.loading = true;
    this.user_id;
    this.access_code
    console.log("user id & code", this.user_id, this.access_code)
  

    if (this.access_code == "QWX307ABz" && this.user_id == "000012xd") {
      console.log("successs")
      this.router.navigate(["/preamble"]);
    }
    else {
      alert("Access code is incorrect")
      this.loading = false;
    }

    
  }

}

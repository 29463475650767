import { Type } from "@angular/compiler";
import { Component, HostListener, OnInit, EventEmitter, Output } from "@angular/core";
import { ViewChild, ElementRef } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { StepperOrientation } from "@angular/cdk/stepper";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { MatExpansionModule } from "@angular/material/expansion";


import { PropertiesService } from "../../shared/services/properties.service";
import { BeneficiariesService } from "../../shared/services/beneficiaries.service";
import { DependantsService } from "../../shared/services/dependants.service";
import { SpousalService } from "../../shared/services/spousal.service";
import { ChildrenService } from "../../shared/services/children.service";
import { AngularFirestore } from "@angular/fire/firestore";

@Component({
  selector: "app-gifts",
  templateUrl: "./gifts.component.html",
  styleUrls: ["./gifts.component.css"],
})
export class GiftsComponent implements OnInit {
  panelOpenState = false;
  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  fourthFormGroup: FormGroup;
  public searchStr: string = "";
  public age;
  address;
  isVertical = false;
  orientation: StepperOrientation = "vertical";
  smallScreen: boolean;
  completed: boolean = false;
  state: string;
  title = "htmltopdf";
  anothername: string;
  residential: string;
  child_address;
  myVar1;
  myVar2;
  myVar3;
  myVar4;
  basic;
  basic_doc_id
  bene;
  dependants
  organization_name
  items;
  gifts_allo;
  showSummary: boolean = true;
  listProp = "no"

  showSave = "yes";

  new_form = 'no';
  editing = 'no';
  ben_list;
  params;
  spouse_first_name
  spouse_l_name
  spouses;

  children_list;
  children = ""
  dep_name = [""];
  percentage = ""
  ben_name = [""];
  spouses_name = [""];
  child_percentage = ""
  bene_percentage = ""
  dep_percentage = ""


  spouses_name_arr = [""];
  percentage_arr = [""];
  children_arr = [""];
  child_percentage_arr = [""];
  dep_name_arr = [""];
  dep_percentage_arr = [""];
  ben_name_arr = [""];
  userInput = [""];
  bene_percentage_arr = [""];

  showSummaryButton;

  property_name = ""
  beneficiary_names = [""];
  dependants_name = ""
  property_details = ""
  gift_doc_id;

  share = '';


  // public all_list: Array<any>;



  step = 0;
  all_list = [];
  items2 = ['Item 1', 'Item 2', 'Item 3'];
  // userInput: string[] = [];

  @Output() callParent = new EventEmitter();
  constructor(
    private _formBuilder: FormBuilder,
    private breakpointObserver: BreakpointObserver,
    public property_service: PropertiesService,
    public beneficiary_service: BeneficiariesService,
    public dependants_service: DependantsService,
    public spousal_service: SpousalService,
    public child_service: ChildrenService,
    private db: AngularFirestore
  ) { }

  ngOnInit() {
    this.fourthFormGroup = this._formBuilder.group({

      ben_name: [""],
      percentage: [""],
      dep_name: [""],
      spouses_name: [""],
      children: [""],
      bene_percentage: [""],
      percc: [""],
      dep_percentage: [""],
      child_percentage: [""],
      property_name: [""],
      property_details: [""],
      share: [""]



    });


    //RETRIEVE RESIDENCE DETAILS

    this.property_service
      .retrieve_residence()
      .subscribe((basic) => {
        console.log("RESUDENT PROPERTTIES CHECK BASIC REQUIR:::", basic)
        this.basic = basic
        let data = basic[0];
        // this.basic_doc_id = data.id
        console.log("LENGHT:::", this.basic.length)

        if (basic.length > 0) {
          let data = basic[0];
          this.basic_doc_id = data.id

        }
      })



    this.beneficiary_service
      .retrieve_beneficiaries()
      .subscribe((basic2) => {
        console.log("BENE REQUIR:::", basic2)
        this.bene = basic2

        console.log("BENE REQUI his.beneR:::", this.bene)
        this.items = [];
      

        for (var name of this.bene) {
          console.log("BEN NAME", name);
          this.all_list.push({
            name: name.data.org_name ||  name.data.first_name + " " + name.data.last_name
          });
        }
        console.log("BENE all_list LISTSS::: ", this.all_list);

        if (basic2.length > 0) {
          let data = basic2[0];
          this.basic_doc_id = data.id


        }
      })


    this.beneficiary_service
      .retrieve_gift_allocation()
      .subscribe((basic2) => {
        console.log("ALLO CHECK BASIC REQUIR:::", basic2)
        let data = basic2[0];
        this.gifts_allo = basic2



        if (basic2.length > 0) {
          this.ben_name = data.data.ben_name
          this.percentage = data.data.percentage
          this.dep_name = data.data.dep_name
          this.spouses_name = data.data.spouses_name
          this.children = data.data.children
          this.bene_percentage = data.data.bene_percentage
          this.dep_percentage = data.data.dep_percentage
          this.child_percentage = data.data.child_percentage
          this.property_details = data.data.property_details
          this.property_name = data.data.property_name

          console.log("bene_percentage:::", this.bene_percentage)
        }


      })

    this.spousal_service
      .retrieve_spousal()
      .subscribe((basic) => {
        console.log("CHECK BASIC REQUIR:::", basic)
        this.spouses = basic
        let data = basic[0];


        for (var name of this.spouses) {
          console.log("spouses NAME", name);
          this.all_list.push({
            name: name.data.spouse_first_name + " " + name.data.spouse_l_name
          });
        }
        console.log("spouses all_list LISTSS::: ", this.all_list);



        if (basic.length > 0) {
          let data = basic[0];
          this.basic_doc_id = data.id
          this.spouse_first_name = data.data.spouse_first_name
          this.spouse_l_name = data.data.spouse_l_name



          console.log("firstname:::", this.spouse_l_name)

        }
      })


    this.child_service
      .retrieve_child()
      .subscribe((basic) => {
        console.log("CHILDREN CHECK BASIC REQUIR:::", basic)
        this.children_list = basic
        let data = basic[0];

        for (var name of this.children_list) {
          console.log("children_list NAME", name);
          this.all_list.push({
            name: name.data.c_first_name + " " + name.data.c_last_name
          });
        }
        console.log("children_list all_list LISTSS::: ", this.all_list);



        if (basic.length > 0) {
          let data = basic[0];
          this.basic_doc_id = data.id


        }
      })



    this.dependants_service
      .retrieve_dependants()
      .subscribe((basic2) => {
        console.log("CHECK BASIC REQUIR:::", basic2)
        this.dependants = basic2
      
        for (var name of this.dependants) {
          console.log("dependants NAME", name);
          this.all_list.push({
            name: name.data.d_first_name + " " + name.data.d_last_name
          });
        }
        console.log("dependants all_list LISTSS::: ", this.all_list);


        console.log("ITEM LISTSS::: ", this.items);

        if (basic2.length > 0) {
          let data = basic2[0];
          this.basic_doc_id = data.id


        }
      })

    console.log("WHAT IN all_list", this.all_list)

  }

  // handleInputChange(index: number, value: string) {
  //   this.userInput[index] = value;
  //   console.log("INPUT HANDLE::::", this.userInput[index])
  //   console.log("INPUT HANDLE VALUE::::", value)
  // }

  deleteForm(data) {
    console.log("DELETE VALUE ", data);
    console.log("DELETE ID ", data.id);
    // console.log("Property Name ", data.data.property_name);
    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid

    if (userUid) {
      console.log("USER EXISTS--")
      return new Promise<any>((resolve, reject) => {
        this.db
          .collection("gift_allocation")
          .doc(data.id)
          .delete()
      });

    }
  }



  setStep(index: number) {
    this.step = index;

    // console.log("WHAT IS INDEX SAVED ??", this.indexxSaved)
    // if (this.indexxSaved == index) {
    //   this.indexxSaved = index
    // }

  }



  edit_form(data) {
    this.new_form = 'no'
    this.editing = 'yes'
    this.showSave = "no"

    console.log("EDITING--", this.editing)

    this.beneficiary_service
      .retrieve_gift_edit(data)
      .subscribe((basic) => {
        console.log("GIFT ALLO SERVICE:::", basic)
        this.gift_doc_id = basic.id


        this.bene_percentage = basic.data.bene_percentage
        this.beneficiary_names = basic.data.beneficiary_names
        this.child_percentage = basic.data.child_percentage
        this.children = basic.data.children
        this.dep_percentage = basic.data.dep_percentage
        this.dependants_name = basic.data.dependants_name
        this.percentage = basic.data.percentage
        this.property_details = basic.data.property_details
        this.property_name = basic.data.property_name
        this.spouses_name = basic.data.spouses_name

        this.ben_name = basic.data.ben_name


      })
  }




  proceed(data, details, index) {
    let formData: any = this.fourthFormGroup.value as {};
    console.log("Data ", data);
    console.log("details ", details);
    console.log("perccVALUE ", formData.percc);
    console.log("perccVALUE  INDEX", this.userInput);
    console.log("share Value  INDEX", this.share);

   
    
    console.log("ben_name_arr ", this.ben_name_arr[index]);
    this.step++;
    this.showSummaryButton = "yes"


    this.params = {
      property_name: data,
      property_details: details,
      ben_name: this.ben_name_arr[index] || "none",
      bene_percentage: this.userInput,
      share: this.share
  
    };


    console.log(" this.params ", this.params);

    this.beneficiary_service.save_gift_allocation(this.params)

    this.new_form = 'yes'
    this.editing = 'yes'

    console.log("showSummary::", this.showSummary, "new_form::", this.new_form, "listProp::", this.listProp, "editing:::", this.editing)


    this.callParent.emit("completed9");

  }



  update() {
    let formData: any = this.fourthFormGroup.value as {};
    console.log("VALUE ", formData);

    this.params = {
      property_name: formData.property_name,
      property_details: formData.property_details,
      ben_name: formData.ben_name || "none",
      bene_percentage: this.userInput,
      share: this.share

    };
    console.log(" this.params ", this.params);

    this.beneficiary_service.update_gift_allocation(this.gift_doc_id, this.params)

    this.new_form = 'no'
    this.editing = 'no'

  }

  allocateNew() {
    console.log("<---- RESSETTIN FORM ---->>>")
    // this.fourthFormGroup.reset()


    this.property_name = ""
    this.property_details = ""
    this.dep_name = [""]
    this.ben_name = [""]
    this.spouses_name = [""]
    this.children = ""
    this.percentage = ""
    this.bene_percentage = ""
    this.dep_percentage = ""
    this.child_percentage = ""


    this.new_form = 'yes'
    this.showSummary = false
    this.editing = 'no'
    this.showSummaryButton = "no"


    console.log("showSummary::", this.showSummary, "new_form::", this.new_form, "listProp::", this.listProp, "editing:::", this.editing)
  }


  // GetStats(event) {

  //   this.ben_list = event.target.value
  //   console.log(this.ben_list);

  //   let ben_list = [];


  //   console.log(name);
  //   ben_list.push({
  //     name: this.ben_list


  //   });

  //   console.log("NEW BENLIST", ben_list);


  // }




  goToSummary() {

    this.showSummary = true
    this.new_form = 'no'
    this.listProp = "no"
    this.editing = 'no'

    console.log("showSummary::", this.showSummary, "new_form::", this.new_form, "listProp::", this.listProp, "editing:::", this.editing)


  }
}

import { Type } from "@angular/compiler";
import { Component, HostListener, OnInit, EventEmitter, Output } from "@angular/core";
import { ViewChild, ElementRef } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { StepperOrientation } from "@angular/cdk/stepper";

import { BeneficiariesService } from "../../shared/services/beneficiaries.service";
import { AngularFirestore } from "@angular/fire/firestore";

@Component({
  selector: "app-other-beneficiary",
  templateUrl: "./other-beneficiary.component.html",
  styleUrls: ["./other-beneficiary.component.css"],
})
export class OtherBeneficiaryComponent implements OnInit {
  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  fourthFormGroup: FormGroup;
  public searchStr: string = "";
  public age;
  address;
  isVertical = false;
  orientation: StepperOrientation = "vertical";
  smallScreen: boolean;
  completed: boolean = false;
  state: string;
  title = "htmltopdf";
  anothername: string;
  residential: string;
  child_address;
  person_org = ''
  listProp = "no"

  showSave = "yes";

  new_form = 'no';
  editing = 'no';
  basic_doc_id;
  basic;

  first_name = ''
  mid_name = ''
  last_name = ''
  relationship = ''
  res_address = ''
  mail_address = ''
  digi_address = ''
  occupation = ''
  org_name = ''
  org_location = ''
  org_mail = ''
  org_digi = ''

  @Output() callParent = new EventEmitter();
  constructor(
    private _formBuilder: FormBuilder,
    private breakpointObserver: BreakpointObserver,
    public beneficiary_service: BeneficiariesService,
    private db: AngularFirestore
  ) { }

  ngOnInit() {
    this.fourthFormGroup = this._formBuilder.group({
      first_name: [""],
      mid_name: [""],
      last_name: [""],
      relationship: [""],
      res_address: [""],
      mail_address: [""],
      digi_address: [""],
      person_org: [""],
      occupation: [""],
      org_name: [""],
      org_location: [""],
      org_mail: [""],
      org_digi: [""]
    });



    this.beneficiary_service
      .retrieve_beneficiaries()
      .subscribe((basic) => {
        console.log("CHECK BASIC REQUIR:::", basic)
        this.basic = basic
        let data = basic[0];

        console.log("LENGHT:::", this.basic.length)

        if (basic.length > 0) {
          let data = basic[0];
          this.basic_doc_id = data.id
      

          this.first_name = data.data.first_name
          this.mid_name = data.data.mid_name
          this.last_name = data.data.last_name
          this.relationship = data.data.relationship
          this.res_address = data.data.res_address
          this.mail_address = data.data.mail_address
          this.digi_address = data.data.digi_address
          this.person_org = data.data.person_org
          this.occupation = data.data.occupation
          this.org_name = data.data.org_name
          this.org_location = data.data.org_location
          this.org_mail = data.data.org_mail
          this.org_digi = data.data.org_digi

      


        }
      })
  }



  public modelChange(str: string) {

  }

  all_forms() {
    this.completed = true;
    this.state = "done";
    console.log(this.firstFormGroup.value);
    console.log(this.secondFormGroup.value);
    this.firstFormGroup.reset();
    this.secondFormGroup.reset();
  }




  newProperty2() {

    this.fourthFormGroup.reset()
    this.showSave = "yes"
    this.new_form = 'yes'

    this.first_name = ''
    this.mid_name = ''
    this.last_name = ''
    this.relationship = ''
    this.res_address = ''
    this.mail_address = ''
    this.digi_address = ''
    this.occupation = ''
    this.org_name = ''
    this.org_location = ''
    this.org_mail = ''
    this.org_digi = ''
    this.person_org = ''
  }




  edit_form(data) {
    this.new_form = 'yes'
    this.editing = 'yes'
    this.showSave = "no"

    this.beneficiary_service
      .retrieve_beneficiaries_edit(data)
      .subscribe((basic) => {
        console.log("CHILD SERVICE:::", basic)
        console.log("DEPEM SERVICE DATA ID:::", basic.id)

        this.basic_doc_id = basic.id


        this.first_name = basic.data.first_name
        this.mid_name = basic.data.mid_name
        this.last_name = basic.data.last_name
        this.relationship = basic.data.relationship
        this.res_address = basic.data.res_address
        this.mail_address = basic.data.mail_address
        this.digi_address = basic.data.digi_address
        this.person_org = basic.data.person_org
        this.occupation = basic.data.occupation
        this.org_name = basic.data.org_name
        this.org_location = basic.data.org_location
        this.org_mail = basic.data.org_mail
        this.org_digi = basic.data.org_digi


      })
  }

  deleteForm(data) {
    console.log("DELETE VALUE ", data);
    console.log("DELETE ID ", data.id);


    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid


    if (userUid) {
      console.log("USER EXISTS--")
      return new Promise<any>((resolve, reject) => {
        this.db
          .collection("beneficiaries")
          .doc(data.id)
          .delete()
      });

    }



  }



  save_trust() {
    let formData: any = this.fourthFormGroup.value as {};
    console.log("VALUE ", formData);

    this.beneficiary_service.save_dependant(formData)

    this.new_form = 'no'
    this.callParent.emit("completed8");

  }


  update() {
    let formData: any = this.fourthFormGroup.value as {};
    console.log("VALUE ", formData);

    this.beneficiary_service.update_dependant(this.basic_doc_id, formData)

    this.new_form = 'no'
    this.editing = 'no'

    this.callParent.emit("completed8");
  }



}

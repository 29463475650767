import { Type } from "@angular/compiler";
import { Component, HostListener, OnInit, EventEmitter, Output } from "@angular/core";
import { ViewChild, ElementRef } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { StepperOrientation } from "@angular/cdk/stepper";

import { BeneficiariesService } from "../../shared/services/beneficiaries.service";
import { ChildrenService } from "../../shared/services/children.service";
import { AngularFirestore } from "@angular/fire/firestore";
@Component({
  selector: "app-guardian",
  templateUrl: "./guardian.component.html",
  styleUrls: ["./guardian.component.css"],
})
export class GuardianComponent implements OnInit {
  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  fourthFormGroup: FormGroup;
  public searchStr: string = "";
  public age;
  address;
  isVertical = false;
  orientation: StepperOrientation = "vertical";
  smallScreen: boolean;
  completed: boolean = false;
  state: string;
  title = "htmltopdf";
  anothername: string;
  residential: string;
  child_address;
  addG;
  listProp = "no"

  showSave = "yes";

  new_form = 'no';
  editing = 'no';
  basic_doc_id;
  basic;
  child_detail;
  retrieve_child_detail

  f_name = ''
  M_name = ''
  l_name = ''
  res_address = ''
  mail_address = ''
  digi_address = ''
  occupation = ''
  relationship = ''
  @Output() callParent = new EventEmitter();
  
  constructor(
    private _formBuilder: FormBuilder,
    private breakpointObserver: BreakpointObserver,
    public beneficiary_service: BeneficiariesService,
    public child_service: ChildrenService,
    private db: AngularFirestore
  ) { }

  ngOnInit() {
    this.fourthFormGroup = this._formBuilder.group({
      f_name: [""],
      M_name: [""],
      l_name: [""],
      res_address: [""],
      relationship: [""],
      mail_address: [""],
      digi_address: [""],
      occupation: [""],
      appoint_guardian: [""],
      child_detail: [""]
    });


    this.beneficiary_service
      .retrieve_guardian()
      .subscribe((basic) => {
        console.log("CHECK BASIC REQUIR:::", basic)
        this.basic = basic
        let data = basic[0];

        console.log("LENGHT:::", this.basic.length)

        if (basic.length > 0) {
          let data = basic[0];
          this.basic_doc_id = data.id

          this.f_name = data.data.f_name
          this.M_name = data.data.M_name
          this.l_name = data.data.l_name
          this.relationship = data.data.relationship
          this.res_address = data.data.res_address
          this.mail_address = data.data.mail_address
          this.digi_address = data.data.digi_address
          this.occupation = data.data.occupation
          


        }
      })
    
    
    this.child_service
      .retrieve_child_details()
      .subscribe((basic) => {
        console.log("Retirve children:", basic)
        this.retrieve_child_detail = basic
        let data = this.retrieve_child_detail[0];

        console.log("LENGHT:::", this.retrieve_child_detail.length)

        if (basic.length > 0) {
          let data = basic[0];
          // this.basic_doc_id = data.id


        }
      })
  }


  public modelChange(str: string) {

  }

  newProperty2() {

    this.fourthFormGroup.reset()
    this.showSave = "no"
    this.new_form = 'yes'


    this.f_name = ''
    this.M_name = ''
    this.l_name = ''
    this.res_address = ''
    this.relationship = ''
    this.mail_address = ''
    this.digi_address = ''
    this.occupation = ''
    this.child_detail = ''
  }



  edit_form(data) {
    this.new_form = 'yes'
    this.editing = 'yes'
    this.showSave = "no"
    this.beneficiary_service
      .retrieve_guard_edit(data)
      .subscribe((basic) => {
        console.log("CHILD SERVICE:::", basic)
        console.log("DEPEM SERVICE DATA ID:::", basic.id)

        this.basic_doc_id = basic.id



        this.f_name = data.data.f_name
        this.M_name = data.data.M_name
        this.l_name = data.data.l_name
        this.relationship = data.data.relationship
        this.res_address = data.data.res_address
        this.mail_address = data.data.mail_address
        this.digi_address = data.data.digi_address
        this.occupation = data.data.occupation


      })
  }

  deleteForm(data) {
    console.log("DELETE VALUE ", data);
    console.log("DELETE ID ", data.id);


    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid


    if (userUid) {
      console.log("USER EXISTS--")
      return new Promise<any>((resolve, reject) => {
        this.db
          .collection("guardian")
          .doc(data.id)
          .delete()
      });

    }
  }



  save_trust() {
    let formData: any = this.fourthFormGroup.value as {};
    console.log("VALUE ", formData);

    this.beneficiary_service.save_guardian(formData)

    this.new_form = 'no'
    this.callParent.emit("completed10");

  }

  params

  proceed(data) {
    let formData: any = this.fourthFormGroup.value as {};
    console.log("Data ", data);
    console.log("VALUE ", formData);


    this.params = {
      child_name: data,
      f_name: formData.f_name,
      M_name: formData.M_name,
      l_name: formData.l_name,
      relationship: formData.relationship,
      res_address: formData.res_address,
      mail_address: formData.mail_address,
      digi_address: formData.digi_address,
      occupation: formData.occupation

    };

    console.log(" this.params ", this.params);

    this.beneficiary_service.save_guardian(this.params)


    this.new_form = 'no'
    this.editing = 'no'

    this.callParent.emit("completed9");

  }


  update(data) {
    let formData: any = this.fourthFormGroup.value as {};
    console.log("VALUE ", formData);
    console.log("Data ", data);

    this.params = {
      child_name: data,
      f_name: formData.f_name,
      M_name: formData.M_name,
      l_name: formData.l_name,
      relationship: formData.relationship,
      res_address: formData.res_address,
      mail_address: formData.mail_address,
      digi_address: formData.digi_address,
      occupation: formData.occupation

    };

    console.log(" this.params ", this.params);
    console.log(" DOC ID ", this.basic_doc_id);

    this.beneficiary_service.update_guardian(this.basic_doc_id, this.params)

    this.new_form = 'no'
    this.editing = 'no'
    this.callParent.emit("completed10");

  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { AuthService } from "../../../shared/services/auth.service";
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  isLoggedIn: boolean

  constructor(public router: Router, public authService: AuthService) {
    this.userDetails()
  }

  ngOnInit() {
    this.userDetails()
  }


  userDetails() {
    let userData = JSON.parse(localStorage.getItem("user"));
    if (userData) {

      console.log("USER DATA::", userData)
      this.isLoggedIn = true;
    }
    else {
      console.log("USER DATA::", userData)
      this.isLoggedIn = false;



    }

  }


  logout() {
    this.authService.SignOut()
      .then(
        data => {
          console.log("DATA IS", data)


        },
        error => {
          console.log("ERROR", error)

        });
  }

}
